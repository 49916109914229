import React, {
  ReactElement,
  useEffect,
  SyntheticEvent,
  RefObject,
} from "react";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField";

import {
  RequestState,
  City,
  EnergyCalculatorFormValues,
} from "../../../../types";
import TextField from "../TextFieldWithScroll";

import {
  AutocompleteWrapper,
  Autocomplete,
  AutocompleteOptions,
  AutocompleteList,
  AutocompleteListItem,
  AutocompleteItemTitle,
  AutocompleteItemSubTitle,
} from "./styled";
import { useAutocomplete } from "../../../../hooks/useAutocomplete";

interface TextFieldCityProps extends OutlinedTextFieldProps {
  cities: RequestState<City[]>;
  cityId: string;
  setCity: (
    values: Record<string, unknown>[],
    shouldValidate?: boolean
  ) => void;
  triggerValidation: (payload: Array<keyof EnergyCalculatorFormValues>) => void;
}

export const TextFieldCity = ({
  id,
  cities: { isPending, data },
  cityId,
  setCity,
  triggerValidation,
  ...rest
}: TextFieldCityProps): ReactElement => {
  const setCityValue = (city: City) => {
    setCity([{ postalCode: city.postcode }, { cityId: city.id }]);
    triggerValidation(["postalCode", "cityId"]);
    setIsOpen(false);
  };
  useEffect(() => {
    if (data?.length === 1) {
      const city = data[0];
      setCityValue(city);
    }
  }, [data]);

  const handleAutocompleteItemSelection = () => {
    if (!data?.length) {
      return;
    }

    setCity([
      { cityId: data[cursor]?.id },
      { postalCode: data[cursor]?.postcode },
    ]);
    setIsOpen(false);
  };

  const getCityName = (cities: City[] | null, cityId: string): string => {
    let result = "";

    if (!cities) {
      return result;
    }

    cities.forEach((city) => {
      if (Number(city.id) === Number(cityId)) {
        result = city.name;
      }
    });
    return result;
  };

  const {
    setIsOpen,
    wrapperRef,
    isOpen,
    cursor,
    setCursor,
  } = useAutocomplete();

  return (
    <AutocompleteWrapper ref={wrapperRef as RefObject<HTMLDivElement>}>
      <TextField
        id={id}
        inputProps={{ maxlength: 5 }}
        onFocus={() => setIsOpen(true)}
        onChange={(event: SyntheticEvent<EventTarget>) => {
          const { value } = event.target as HTMLInputElement;
          if (!value.length) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
        {...rest}
      />
      {cityId && <Autocomplete>{getCityName(data, cityId)}</Autocomplete>}
      {!isPending && Boolean(data?.length) && isOpen && (
        <AutocompleteOptions>
          <AutocompleteList className="scrollbar">
            {data?.map((city, i) => (
              <AutocompleteListItem
                key={city.id}
                onClick={handleAutocompleteItemSelection}
                onMouseEnter={() => setCursor(i)}
                tabIndex={0}
              >
                <AutocompleteItemTitle isHovered={cursor === i}>
                  {city.postcode}
                </AutocompleteItemTitle>
                <AutocompleteItemSubTitle>{city.name}</AutocompleteItemSubTitle>
              </AutocompleteListItem>
            ))}
          </AutocompleteList>
        </AutocompleteOptions>
      )}
    </AutocompleteWrapper>
  );
};

TextFieldCity.defaultProps = {
  variant: "outlined",
};

export default TextFieldCity;
