import styled, { css } from "styled-components";

export const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Autocomplete = styled.div`
  pointer-events: none;
  position: absolute;
  top: 19px;
  right: 16px;
  font-size: 16px;
  line-height: 1.31;
  text-align: right;
  color: #abb3b5;
`;

export const AutocompleteOptions = styled.div`
  position: absolute;
  top: 63px;
  right: 0;
  left: 0;
  padding: 14px;
  border-radius: 16px;
  box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  background-color: #ffffff;
  z-index: 100;
`;

export const AutocompleteList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 120px;
  overflow-y: auto;
`;

export const AutocompleteListItem = styled.li`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 14px 14px 0;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: calc(100% - 14px);
    height: 1px;
    margin-right: 14px;
    border-radius: 1px;
    background-color: #ecf0f1;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
`;

interface AutocompleteItemTitleProps {
  isHovered?: boolean;
}

export const AutocompleteItemTitle = styled.span<AutocompleteItemTitleProps>`
  font-size: 16px;
  line-height: 1.31;
  color: #263242;

  ${({ isHovered }) =>
    isHovered &&
    css`
      color: #526df2;
    `}
`;

export const AutocompleteItemSubTitle = styled.span`
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: #abb3b5;
`;
