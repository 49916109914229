import React, { ReactElement, RefObject, useEffect } from "react";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField";

import {
  EnergyCalculatorFormValues,
  RequestState,
  Street,
} from "../../../../types";
import TextField from "../TextFieldWithScroll";
import {
  AutocompleteWrapper,
  AutocompleteOptions,
  AutocompleteList,
} from "../TextFieldCity/styled";

import { AutocompleteListItem } from "./styled";
import { useAutocomplete } from "../../../../hooks/useAutocomplete";
interface TextFieldStreetProps extends OutlinedTextFieldProps {
  streets: RequestState<Street[]>;
  street: string;
  setStreet: (
    values: Record<string, unknown>[],
    shouldValidate?: boolean
  ) => void;
  triggerValidation: (payload: keyof EnergyCalculatorFormValues) => void;
  onStreetChange: () => void;
}

const TextFieldStreet = ({
  streets: { isPending, data },
  street,
  setStreet,
  triggerValidation,
  onStreetChange,
  ...rest
}: TextFieldStreetProps): ReactElement => {
  const filterStreets = (streets: Street[] | null, term: string) => {
    return streets?.filter((street) =>
      street.name
        .toLocaleLowerCase()
        .replace(/\s/g, "")
        .includes(term.toLocaleLowerCase().replace(/\s/g, ""))
    );
  };

  const autocomplete = filterStreets(data, street);

  const handleAutocompleteItemSelection = () => {
    if (!autocomplete?.length) {
      return;
    }

    setStreet([{ street: autocomplete[cursor]?.name }, { houseNumber: "" }]);
    setIsOpen(false);
    triggerValidation("street");
  };

  const {
    setIsOpen,
    wrapperRef,
    isOpen,
    cursor,
    setCursor,
  } = useAutocomplete();

  return (
    <AutocompleteWrapper ref={wrapperRef as RefObject<HTMLDivElement>}>
      <TextField
        onFocus={() => setIsOpen(true)}
        onChange={() => {
          if (!isOpen) {
            setIsOpen(true);
          }
          onStreetChange();
        }}
        {...rest}
      />
      {!isPending &&
        autocomplete &&
        Boolean(autocomplete?.length) &&
        isOpen &&
        street.length >= 1 && (
          <AutocompleteOptions>
            <AutocompleteList className="scrollbar">
              {autocomplete.map((street, i) => {
                return (
                  <AutocompleteListItem
                    key={street.name}
                    onClick={handleAutocompleteItemSelection}
                    onMouseEnter={() => setCursor(i)}
                    isHovered={cursor === i}
                    tabIndex={0}
                  >
                    {street.name}
                  </AutocompleteListItem>
                );
              })}
            </AutocompleteList>
          </AutocompleteOptions>
        )}
    </AutocompleteWrapper>
  );
};

TextFieldStreet.defaultProps = {
  variant: "outlined",
};

export default TextFieldStreet;
