import styled from "styled-components";
import MaterialTextField from "@material-ui/core/TextField";

import { TextFieldStyle } from "../../styled";

const TextField = styled(MaterialTextField)`
  ${TextFieldStyle}
`;

TextField.defaultProps = {
  variant: "outlined",
};

export default TextField;
