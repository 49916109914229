import React, { ReactElement, useRef } from "react";
import { TextFieldProps } from "@material-ui/core";

import TextField from "../TextField";

const TextFieldWithScroll = ({
  onFocus,
  ...rest
}: TextFieldProps): ReactElement => {
  const fieldRef = useRef<HTMLDivElement>(null);

  const handleFocusOnField = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onFocus) {
      onFocus(e);
    }
    fieldRef?.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return <TextField ref={fieldRef} onFocus={handleFocusOnField} {...rest} />;
};

export default TextFieldWithScroll;
