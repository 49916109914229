import styled, { css } from "styled-components";

interface AutocompleteListItemProps {
  isHovered?: boolean;
}

export const AutocompleteListItem = styled.li<AutocompleteListItemProps>`
  cursor: pointer;
  position: relative;
  padding: 14px;
  font-size: 16px;
  line-height: 1.31;
  word-break: break-all;
  color: #263242;

  &:hover {
    color: #526df2;
  }

  ${({ isHovered }) =>
    isHovered &&
    css`
      color: #526df2;
    `}

  &::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: calc(100% - 28px);
    height: 1px;
    margin: 0 14px;
    border-radius: 1px;
    background-color: #ecf0f1;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
`;
